import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import { Category } from '@jelly/templates'

const DEFAULT_CHANNEL = {
	primary: {
		name: 'Business Basics',
		slug: 'business-basics',
	},
	sub: {
		name: 'Starting a Business',
		slug: 'starting-a-business',
	},
}

const Index = ({ data: { props }, pageContext: { canonical } }) => {
	// Take the path from thumbnail and assign it to meta.ogImage to fix the issue with social sharing for this template type.
	props.meta.ogImage = props.thumbnail.path
	props.meta.canonical = canonical
	props.canonical = canonical

	props.channels = !!props.channels ? props.channels : DEFAULT_CHANNEL
	props.breadcrumbs = !!props.breadcrumbs ? props.breadcrumbs : props.channels

	return <Category {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	query seo($slug: String!, $preview: Boolean) {
		# We always need to return props to ve injected on the component
		props: category(slug: $slug, preview: $preview, cache: false) {
			adsense
			adsenseMobile
			canonical
			docType
			guid
			hasVwo
			lastModified: displayModified
			published
			structuredData
			subtitle
			title
			sections {
				__typename
				id
				isInToc
				order
				tocTitle
				type
				... on SectionBestPicks {
					title
					bestpicks {
						description
						tabTitle
						fullTitle
						review {
							serviceName: displayName
							canonical
							id
							pricing {
								...pricingFields
							}
							thumbnail {
								path
							}
						}
					}
				}
				... on SectionReviews {
					intro
					reviews {
						title
						canonical
						pricing {
							...pricingFields
						}
						review {
							title
						}
						thumbnail {
							path
						}
					}
				}
				... on SectionWidget {
					title
					widget {
						widgetId
					}
				}
				... on SectionContainer {
					title
					container {
						isBuyingGuide
						html
					}
				}
				... on SectionMoreCoverage {
					isActive
					title
					isActive
					articles {
						title
						canonical
						author {
							displayName
						}
						thumbnail {
							path
						}
					}
				}
				... on SectionOurApproach {
					isActive
					ourapproach {
						isClonable
						servicesConsidered
						servicesTested
						servicesSelected
						linkText
						linkUrl
					}
				}
				... on SectionServices {
					services {
						canonical
						id
					}
				}
			}
			author {
				name: displayName
				bio
				thumbnail
				status
				type
				slug
				title
				image
				social {
					facebook
					linkedin
					twitter
					website
				}
			}
			breadcrumbs {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			channels {
				primary {
					name
					slug
				}
				sub {
					name
					slug
				}
			}
			tocElements {
				id
				title
			}
			thumbnail {
				path
				caption
				isBlurred
			}
			meta {
				robots
				description
				title
			}
		}
	}
`
